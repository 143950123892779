
import {
  Audits,
  Companies,
  Courses,
  Instructors,
  OnlineCourses,
  Statistics
} from "@/api";
import {
  Company,
  Course,
  EarningFilter,
  EarningItem,
  Gender,
  Service
} from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import TitleHeader from "@/components/common/TitleHeader.vue";
import moment from "moment";
import { Component, Vue, Watch } from "vue-property-decorator";

function filtersToFileName(filter: EarningFilter): string {
  const filterParams: Array<string> = [];
  const props = Object.keys(filter);
  for (const prop of props) {
    if (filter[prop as keyof EarningFilter] !== "") {
      filterParams.push(`${filter[prop as keyof EarningFilter]}`);
    }
  }
  let fileName = "";
  if (filterParams.length > 0) {
    fileName = `${filterParams.join("_")}.xlsx`;
  }
  return fileName;
}

@Component({
  components: {
    TitleHeader,
    PageTitle
  }
})
export default class EarningPage extends Vue {
  headers = [{ icon: "mdi-piggy-bank-outline", header: "Earnings" }];
  buttonsArray = [
    {
      text: "Reset filters",
      icon: "",
      color: "gray",
      action: "reset"
    },
    {
      text: "Export",
      icon: "",
      color: "dark",
      action: "export"
    }
  ];
  menu1 = false;
  menu2 = false;
  items: Array<EarningItem> = [];
  companies: Array<Company> = [];
  courses: Array<Course> = [];
  audits: Array<Course> = [];
  onlineCourses: Array<Course> = [];
  services: { genders: Array<Gender>; services: Array<Service> } = {
    services: [],
    genders: []
  };
  currentDate = moment().format("YYYY-MM-DD");
  startDate = moment().format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");
  filters: EarningFilter = {
    companyName: "",
    startDate: moment().format("DD-MM-YYYY"),
    endDate: moment().format("DD-MM-YYYY"),
    courseName: "",
    auditName: "",
    onlineCourseName: "",
    instructorType: ""
  };
  get startDateText() {
    return moment(this.startDate).format("DD/MM/YYYY");
  }
  get endDateText() {
    return moment(this.endDate).format("DD/MM/YYYY");
  }
  get instructorTypes() {
    return this.services.services;
  }
  @Watch("filters", { deep: true })
  async onFiltersChange() {
    const filters: any = this.filters;
    const filtersList = Object.keys(filters);
    filtersList.forEach(filter => {
      if (filters[filter] === null) {
        filters[filter] = "";
      }
    });
    this.items = await Statistics.getEarningsData(filters);
  }
  @Watch("startDate", { deep: true })
  onStartDateChange() {
    this.menu1 = false;
    this.filters.startDate = moment(this.startDate).format("DD-MM-YYYY");
    this.filters.endDate = moment(this.endDate).format("DD-MM-YYYY");
  }
  @Watch("endDate", { deep: true })
  onEndDateChange() {
    this.menu2 = false;
    this.filters.endDate = moment(this.endDate).format("DD-MM-YYYY");
  }
  async created() {
    try {
      [
        this.companies,
        this.courses,
        this.audits,
        this.onlineCourses,
        this.services,
        this.items
      ] = await Promise.all([
        Companies.listWithoutPagination(),
        Courses.listWithoutPagination(),
        Audits.listWithoutPagination(),
        OnlineCourses.listWithoutPagination(),
        Instructors.servicesAdnGenders(),
        Statistics.getEarningsData(this.filters)
      ]);
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  onResetFilters() {
    this.filters = {
      companyName: "",
      startDate: moment().format("DD-MM-YYYY"),
      endDate: moment().format("DD-MM-YYYY"),
      courseName: "",
      auditName: "",
      onlineCourseName: "",
      instructorType: ""
    };
    this.startDate = moment().format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
  }
  async onExport() {
    try {
      const fileName = filtersToFileName(this.filters);
      const data = await Statistics.exportExcel(this.filters);
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");

      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  formatMoney(value: number | string) {
    const num = parseFloat(value.toString());
    const formattedMoney = new Intl.NumberFormat("en", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2
    }).format(num);
    return formattedMoney.substr(2);
  }
}
